import { StubWorkspace } from '@ardoq/data-model';

const toWorkspaceOption = (workspace: StubWorkspace) => {
  return {
    label: workspace.name,
    value: workspace._id,
  };
};

// Should be the only export aside from types
export const workspaceOptionOperations = {
  toWorkspaceOption,
};
