import { Model } from 'models/model';
import Backbone from 'backbone';
import { getApiUrl } from 'backboneExtensions';
import type { Model as ModelBackboneModel } from 'aqTypes';

export class Models extends Backbone.Collection<ModelBackboneModel> {
  model = Model;
  url = `${getApiUrl(Backbone.Collection)}/api/model`;
}

export default {
  collection: new Models(),
};
