import { uniqBy } from 'lodash';
import { componentOptionOperations } from 'models/utils/componentOptionOperations';
import { referenceOptionOperations } from 'models/utils/referenceOptionOperations';
import { tagOptionOperations } from 'models/utils/tagOptionOperations';
import { workspaceOptionOperations } from 'models/utils/workspaceOptionOperations';
import { getPerspectivesSuggestionsLoaders } from './getPerspectivesSuggestionsLoaders';
import { perspectiveOptionsOperations } from './perspectiveOptionsOperations';
import { PerspectivesRelatedData } from './types';

const getFilterTabProps = (
  perspectivesRelatedData: PerspectivesRelatedData
) => {
  const {
    relatedComponents,
    relatedReferences,
    relatedTags,
    relatedWorkspaces,
    organizationId,
  } = perspectivesRelatedData;
  const workspaceFilterOptions = relatedWorkspaces.map(
    workspaceOptionOperations.toWorkspaceOption
  );
  const tagOptions = uniqBy(
    relatedTags.map(tagOptionOperations.toTagOption),
    'value'
  );
  const componentOptions = relatedComponents.map(
    componentOptionOperations.toComponentOptionWithIdAsDescription
  );

  const parentComponentOptions =
    perspectiveOptionsOperations.optionsWithCurrentComponentAndParentNoneDynamicFilters(
      componentOptions
    );
  const componentOptionsWithCurrentComponent =
    perspectiveOptionsOperations.optionsWithCurrentComponentDynamicFilter(
      componentOptions
    );

  const componentKeyOptionsWithoutCurrentComponent = relatedComponents.map(
    componentOptionOperations.toKeyOption
  );
  const componentKeyOptions =
    perspectiveOptionsOperations.optionsWithCurrentComponentDynamicFilter(
      componentKeyOptionsWithoutCurrentComponent
    );

  const relatedComponentLabelsById = new Map(
    relatedComponents.map(({ name, _id }) => [_id, name])
  );
  const referenceOptions = relatedReferences.map(reference => {
    const sourceComponentName =
      relatedComponentLabelsById.get(reference.source) ?? 'unknown';
    const targetComponentName =
      relatedComponentLabelsById.get(reference.target) ?? 'unknown';

    return referenceOptionOperations.toReferenceOptionWithDisplayTextOrTypeAndComponentNames(
      reference,
      sourceComponentName,
      targetComponentName
    );
  });
  const componentNameOptions = relatedComponents.map(
    componentOptionOperations.toSelectOptionByName
  );
  const { asyncLabelLoaders, asyncSuggestionsLoaders } =
    getPerspectivesSuggestionsLoaders({
      workspaceFilterOptions,
      tagOptions,
      parentComponentOptions,
      componentOptionsWithCurrentComponent,
      componentKeyOptions,
      referenceOptions,
      relatedWorkspaces,
      relatedComponentLabelsById,
      relatedReferences,
      organizationId,
      componentNameOptions,
    });
  return {
    workspaceFilterOptions,
    tagOptions,
    asyncLabelLoaders,
    asyncSuggestionsLoaders,
  };
};
export default getFilterTabProps;
