import { filterUnique } from 'utils/collectionUtil';
import Backbone from 'backbone';
import Workspace from 'models/workspace';
import ViewCollection from './ViewCollection';
import { CollectionView } from './consts';
import { getApiUrl } from 'backboneExtensions';
import { APIWorkspaceAttributes, SortOrder } from '@ardoq/api-types';
import { Workspace as WorkspaceType } from 'aqTypes';
import { smartCompare } from '@ardoq/pagination';
import { localeCompareNumericLowercase } from '@ardoq/locale';

export const modelIdToWorkspaceId = new Map();

const workspaceComparator = (a: WorkspaceType, b: WorkspaceType) =>
  smartCompare(
    a,
    b,
    (workspace: WorkspaceType) => workspace.get('name'),
    SortOrder.ASC,
    {
      sortNumbersAfterStrings: true,
    },
    localeCompareNumericLowercase
  );

export class Workspaces extends ViewCollection<WorkspaceType> {
  model: typeof Workspace.model;

  constructor(...args: any) {
    super(...args);

    this.model = Workspace.model;
    this.url = `${getApiUrl(Backbone.Collection)}/api/workspace/summary`;
    this.comparator = workspaceComparator;
    this.on(
      'add',
      ({
        attributes: { _id, componentModel },
      }: {
        attributes: APIWorkspaceAttributes;
        // We can cache this mapping permanently, these unique tuple ids
        // will not change.
      }) => modelIdToWorkspaceId.set(componentModel, _id)
    );
  }

  getComponentModelIdsInUse() {
    return this.map(ws => ws.get('componentModel') as string).filter(
      filterUnique
    );
  }
}

const globalWorkspaces = new Workspaces();

globalWorkspaces.createCollectionView(CollectionView.BASE_VIEW);

export default {
  collection: globalWorkspaces,
};
