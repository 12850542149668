import {
  ChildTypeGroupingRule,
  FieldGroupingRule,
  ParentGroupingRule,
  PerspectiveGroupingRule,
  PerspectivesGroupsOptions,
  ReferenceGroupingRule,
  TagGroupingRule,
} from '@ardoq/perspectives';
import { ArdoqId, GroupType } from '@ardoq/api-types';
import { exhaustiveCheck } from '@ardoq/common-helpers';

type ValidGroupOptionIds = {
  validWorkspaceIds: ArdoqId[];
  validComponentTypeIds: ArdoqId[];
  validReferenceTypeIds: number[];
  validFieldIds: ArdoqId[];
  validTagIds: ArdoqId[];
};

const getValidGroupOptionIds = (groupsOptions: PerspectivesGroupsOptions) => {
  const validWorkspaceIds = groupsOptions.workspaces.map(
    workspace => workspace.id
  );
  const validComponentTypeIds = Object.values(
    groupsOptions.componentTypesPerWorkspace
  )
    .flat()
    .map(componentType => componentType.id);

  const validReferenceTypeIds = Object.values(
    groupsOptions.referenceTypesPerWorkspace
  )
    .flat()
    .map(referenceType => referenceType.id);

  const validFieldIds = Object.values(groupsOptions.fieldsPerWorkspace)
    .flat()
    .map(field => field.id);

  const validTagIds = Object.values(groupsOptions.tagsPerWorkspace)
    .flat()
    .map(tag => tag.id);

  return {
    validWorkspaceIds,
    validComponentTypeIds,
    validReferenceTypeIds,
    validFieldIds,
    validTagIds,
  };
};

export const removeGroupingRulesBasedOnInvalidOptions = (
  groupingRules: PerspectiveGroupingRule[],
  groupsOptions: PerspectivesGroupsOptions
): PerspectiveGroupingRule[] => {
  const validGroupOptionIds = getValidGroupOptionIds(groupsOptions);
  return groupingRules.filter(groupingRule =>
    isGroupingRuleBasedOnValidOptions(groupingRule, validGroupOptionIds)
  );
};

const isGroupingRuleBasedOnValidOptions = (
  groupingRule: PerspectiveGroupingRule,
  validGroupOptionIds: ValidGroupOptionIds
) => {
  const {
    validWorkspaceIds,
    validComponentTypeIds,
    validReferenceTypeIds,
    validFieldIds,
    validTagIds,
  } = validGroupOptionIds;

  switch (groupingRule.type) {
    case GroupType.WORKSPACE:
    case GroupType.COMPONENT:
    case GroupType.SUBDIVISION:
    case GroupType.PARENT_ALL:
      return true;

    case GroupType.REFERENCE:
      return hasValidWorkspaceIdAndTargetId(
        groupingRule,
        validWorkspaceIds,
        validReferenceTypeIds.map(id => id?.toString())
      );
    case GroupType.PARENT:
      return hasValidWorkspaceIdAndTargetId(
        groupingRule,
        validWorkspaceIds,
        validComponentTypeIds
      );
    case GroupType.TAG:
      return hasValidWorkspaceIdAndTargetId(
        groupingRule,
        validWorkspaceIds,
        validTagIds
      );
    case GroupType.FIELD:
      return hasValidWorkspaceIdAndTargetId(
        groupingRule,
        validWorkspaceIds,
        validFieldIds
      );
    case GroupType.CHILD:
      return hasValidWorkspaceIdAndTargetId(
        groupingRule,
        validWorkspaceIds,
        validComponentTypeIds
      );
    default:
      return exhaustiveCheck(groupingRule);
  }
};

const hasValidWorkspaceIdAndTargetId = (
  groupingRule:
    | ParentGroupingRule
    | ReferenceGroupingRule
    | FieldGroupingRule
    | ChildTypeGroupingRule
    | TagGroupingRule,
  validWorkspaceIds: ArdoqId[],
  validTargetIds: (ArdoqId | number)[]
) =>
  validWorkspaceIds.includes(groupingRule.workspaceId) &&
  (!groupingRule.targetId || validTargetIds.includes(groupingRule.targetId));
