import { debounce } from 'lodash';
import Context from 'context';
import { logError } from '@ardoq/logging';
interface LogMissingModelArgs {
  id: string;
  rootWorkspace: string;
  modelTypeName: 'component' | 'reference' | 'workspace' | 'field';
  additionalInfo?: Record<string, string>;
}
// Patch to prevent a flood of API calls when loading data
// Created ARD-2278 to address the issue more holistically
const debouncedLogError = debounce(logError, 500);
const logMissingModel = ({
  id,
  rootWorkspace,
  modelTypeName,
  additionalInfo,
}: LogMissingModelArgs) => {
  debouncedLogError(Error(`Invalid ${modelTypeName}, model is missing`), null, {
    id,
    rootWorkspace,
    contextWorkspaceIds: Context.workspaces().map(ws => ws.id),
    ...additionalInfo,
  });
};
export default logMissingModel;
