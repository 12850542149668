import { DynamicFilterSelectOptions } from '@ardoq/query-builder';
import { ArdoqId } from '@ardoq/api-types';
import { BasicSelectOption } from './types';

const isCurrentComponentOption = (id: ArdoqId) => {
  return id === DynamicFilterSelectOptions.COMPONENT_CURRENT.value;
};

const getCurrentComponentLabel = () => {
  return DynamicFilterSelectOptions.COMPONENT_CURRENT.label;
};

const isParentNoneOption = (id: ArdoqId) => {
  return id === DynamicFilterSelectOptions.COMPONENT_PARENT_NONE.value;
};

const getParentNoneLabel = () => {
  return DynamicFilterSelectOptions.COMPONENT_PARENT_NONE.label;
};

const optionsWithCurrentComponentDynamicFilter = (
  componentOptions: BasicSelectOption[]
) => {
  return [DynamicFilterSelectOptions.COMPONENT_CURRENT].concat(
    componentOptions
  );
};

const optionsWithCurrentComponentAndParentNoneDynamicFilters = (
  componentOptions: BasicSelectOption[]
) => {
  return [
    DynamicFilterSelectOptions.COMPONENT_PARENT_NONE,
    DynamicFilterSelectOptions.COMPONENT_CURRENT,
  ].concat(componentOptions);
};

// Should be the only export aside from types
export const perspectiveOptionsOperations = {
  isCurrentComponentOption,
  getCurrentComponentLabel,
  isParentNoneOption,
  getParentNoneLabel,
  optionsWithCurrentComponentDynamicFilter,
  optionsWithCurrentComponentAndParentNoneDynamicFilters,
};
