import { StubComponent } from '@ardoq/data-model';
import { APIComponentTypeAttributes, LabeledValue } from '@ardoq/api-types';

const toComponentOptionWithIdAsDescription = (component: StubComponent) => {
  return {
    label: component.name,
    value: component._id,
    description: component._id,
  };
};

const toKeyOption = (component: StubComponent) => {
  return {
    label: component['component-key'],
    value: component['component-key'],
  };
};

const toSelectOptionByName = (component: StubComponent): LabeledValue => {
  return {
    label: component.name,
    value: component.name,
  };
};

const componentTypeToNameOptionWithLabelAsValue = (
  componentType: APIComponentTypeAttributes
): LabeledValue => {
  return {
    label: componentType.name,
    value: componentType.name,
  };
};

// Should be the only export aside from types
export const componentOptionOperations = {
  toKeyOption,
  toComponentOptionWithIdAsDescription,
  toSelectOptionByName,
  componentTypeToNameOptionWithLabelAsValue,
};
