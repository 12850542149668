import { CustomLabelFormatting } from '@ardoq/perspectives';
import { ComponentLabelSource, ReferenceLabelSource } from '@ardoq/data-model';

export const DEFAULT_COMPONENT_LABEL_FORMATTING = ComponentLabelSource.NAME;
export const DEFAULT_REFERENCE_LABEL_FORMATTING =
  ReferenceLabelSource.DISPLAY_TEXT_OR_REFERENCE_TYPE;

export const COMPONENT_STATIC_OPTIONS = Object.values(ComponentLabelSource);
export const REFERENCE_STATIC_OPTIONS = Object.values(ReferenceLabelSource);

const isValidLabelFormatting = (
  labelFormattingValue: string,
  customLabels: CustomLabelFormatting[],
  staticOptions: string[]
): boolean => {
  const validCustomFieldsValues = customLabels.map(it => it.value);
  return (
    validCustomFieldsValues.includes(labelFormattingValue) ||
    staticOptions.includes(labelFormattingValue)
  );
};

export const validLabelFormattingOrDefault = (
  labelFormattingValue: string,
  customLabels: CustomLabelFormatting[],
  staticOptions: string[],
  defaultValue: string
): string =>
  isValidLabelFormatting(labelFormattingValue, customLabels, staticOptions)
    ? labelFormattingValue
    : defaultValue;

export const validComponentLabelFormattingOrDefault = (
  labelFormattingValue: string,
  formattingCustomLabels: CustomLabelFormatting[]
) =>
  validLabelFormattingOrDefault(
    labelFormattingValue,
    formattingCustomLabels,
    COMPONENT_STATIC_OPTIONS,
    DEFAULT_COMPONENT_LABEL_FORMATTING
  );

export const validReferenceLabelFormattingOrDefault = (
  labelFormattingValue: string,
  formattingCustomLabels: CustomLabelFormatting[]
) =>
  validLabelFormattingOrDefault(
    labelFormattingValue,
    formattingCustomLabels,
    REFERENCE_STATIC_OPTIONS,
    DEFAULT_REFERENCE_LABEL_FORMATTING
  );
