import {
  AsyncLabelLoaders,
  AsyncSuggestionsLoaders,
} from '@ardoq/query-builder';
import {
  getLoadComponentTypeNameSuggestionsAsync,
  getLoadReferenceTypeNameSuggestionsAsync,
  getLoadUserSuggestionsAsync,
} from 'search/AdvancedSearch/fetchSuggestionsUtils';
import { perspectiveOptionsOperations } from './perspectiveOptionsOperations';
import { BasicSelectOption, SelectOptionWithDescription } from './types';
import { referenceOptionOperations } from 'models/utils/referenceOptionOperations';
import { StubReferenceWithTypeName, StubWorkspace } from '@ardoq/data-model';
import { ArdoqId } from '@ardoq/api-types';
import {
  componentApi,
  organizationApi,
  referenceApi,
  workspaceApi,
} from '@ardoq/api';
import {
  caseInsensitiveStringIncludes,
  isArdoqError,
} from '@ardoq/common-helpers';

type GetPerspectivesSuggestionsLoadersOptions = {
  workspaceFilterOptions: BasicSelectOption[];
  tagOptions: BasicSelectOption[];
  parentComponentOptions: SelectOptionWithDescription[];
  componentOptionsWithCurrentComponent: SelectOptionWithDescription[];
  componentKeyOptions: BasicSelectOption[];
  referenceOptions: BasicSelectOption[];
  relatedReferences: StubReferenceWithTypeName[];
  relatedComponentLabelsById: Map<string, string>;
  relatedWorkspaces: StubWorkspace[];
  componentNameOptions: BasicSelectOption[];
  organizationId: string | null;
};

const labelContainsTypedPhrase = (label: string, typedPhrase: string) =>
  caseInsensitiveStringIncludes(label, typedPhrase);

export const getPerspectivesSuggestionsLoaders = ({
  workspaceFilterOptions,
  tagOptions,
  parentComponentOptions,
  componentOptionsWithCurrentComponent,
  componentKeyOptions,
  referenceOptions,
  relatedReferences,
  relatedComponentLabelsById,
  relatedWorkspaces,
  componentNameOptions,
  organizationId,
}: GetPerspectivesSuggestionsLoadersOptions): {
  asyncSuggestionsLoaders: AsyncSuggestionsLoaders;
  asyncLabelLoaders: AsyncLabelLoaders;
} => ({
  asyncSuggestionsLoaders: {
    // TODO(chriskr) doesn't make sense in viewpoint builder, requires a dedicated
    // async option loader for the scope defined by the traversal.
    loadComponentSuggestionsAsync: async (typedPhrase: string) => {
      return componentNameOptions.filter(({ label }) =>
        labelContainsTypedPhrase(label, typedPhrase)
      );
    },
    loadUserSuggestionsAsync: getLoadUserSuggestionsAsync(),
    loadComponentSuggestionsIncludingCurrentComponentAsync: async (
      typedPhrase: string
    ) => {
      return componentOptionsWithCurrentComponent.filter(
        ({ label, description }) =>
          labelContainsTypedPhrase(label, typedPhrase) ||
          (description && labelContainsTypedPhrase(description, typedPhrase))
      );
    },
    loadComponentKeySuggestionsAsync: async (typedPhrase: string) => {
      return componentKeyOptions.filter(({ label }) =>
        labelContainsTypedPhrase(label, typedPhrase)
      );
    },
    loadParentComponentSuggestionsAsync: async (typedPhrase: string) => {
      return parentComponentOptions.filter(({ label }) =>
        labelContainsTypedPhrase(label, typedPhrase)
      );
    },
    loadWorkspaceSuggestionsAsync: async (typedPhrase: string) =>
      workspaceFilterOptions.filter(({ label }) =>
        labelContainsTypedPhrase(label, typedPhrase)
      ),
    loadTagSuggestionsAsync: async (typedPhrase: string) =>
      tagOptions.filter(({ label }) =>
        labelContainsTypedPhrase(label, typedPhrase)
      ),
    loadReferenceOIDSuggestionsAsync: async () => referenceOptions,
    loadComponentTypeNameSuggestionsAsync:
      getLoadComponentTypeNameSuggestionsAsync(),
    loadReferenceTypeNameSuggestionsAsync:
      getLoadReferenceTypeNameSuggestionsAsync(),
  },
  asyncLabelLoaders: {
    getComponentKeyLabelAsync: async (key: string) => {
      if (perspectiveOptionsOperations.isCurrentComponentOption(key)) {
        return perspectiveOptionsOperations.getCurrentComponentLabel();
      }
      return key;
    },
    getComponentLabelAsync: async (id: string) => {
      if (perspectiveOptionsOperations.isParentNoneOption(id)) {
        return perspectiveOptionsOperations.getParentNoneLabel();
      }
      if (perspectiveOptionsOperations.isCurrentComponentOption(id)) {
        return perspectiveOptionsOperations.getCurrentComponentLabel();
      }

      const componentName = relatedComponentLabelsById.get(id);
      if (componentName) {
        return componentName;
      }

      const component = await componentApi.fetch(id);
      if (isArdoqError(component)) {
        return `${id} (unknown name)`;
      }
      return component.name;
    },
    getWorkspaceLabelAsync: async (id: string) => {
      const workspace = relatedWorkspaces.find(
        workspace => workspace._id === id
      );
      if (workspace) {
        return workspace.name;
      }
      const result = await workspaceApi.fetch(id);
      if (isArdoqError(result)) {
        return `${id} (unknown name)`;
      }
      return result.name;
    },
    getUserLabelAsync: async (userId: string) => {
      const user = await organizationApi.fetchUserById(userId, organizationId);
      if (isArdoqError(user)) {
        return `${userId} (unknown name)`;
      }
      return `${user.name} (${user.email})`;
    },
    getReferenceLabelAsync: async (id: ArdoqId) => {
      const reference = relatedReferences.find(
        reference => reference._id === id
      );

      if (reference) {
        const sourceComponentName = relatedComponentLabelsById.get(
          reference.source
        );
        const targetComponentName = relatedComponentLabelsById.get(
          reference.target
        );

        return referenceOptionOperations.toReferenceLabelWithDisplayTextOrTypeAndComponentNames(
          {
            reference,
            sourceComponentName: sourceComponentName ?? 'Unknown', // 'Unknown' should not happen as long as database contains consistent data
            targetComponentName: targetComponentName ?? 'Unknown',
          }
        );
      }

      const result = await referenceApi.fetch(id);
      if (isArdoqError(result)) {
        return `${id} (unknown name)`;
      }
      if (result.displayText) {
        return result.displayText;
      }

      const { sourceName, targetName, typeName } = result.ardoq;

      return referenceOptionOperations.toReferenceLabelWithDisplayTextOrTypeAndComponentNames(
        {
          reference: { ...result, typeName: typeName ?? 'Unknown' },
          sourceComponentName: sourceName ?? 'Unknown',
          targetComponentName: targetName ?? 'Unknown',
        }
      );
    },
  },
});
