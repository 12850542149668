import {
  PermissionContext,
  canDo,
  permissionsOperations,
} from '@ardoq/access-control';
import { ArdoqId } from '@ardoq/api-types';
import { Features, hasFeature } from '@ardoq/features';
import { ScenarioModeState, ActiveScenarioState } from 'scope/types';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

/**
 * Check if the current user has the correct access to
 * create a new scenario (checks for privileges and feature).
 * @returns {boolean}
 */
const canCreateScenario = (permissionContext: PermissionContext): boolean =>
  hasFeature(Features.SCENARIOS_BETA) &&
  permissionsOperations.isLoadedPermissionContext(permissionContext) &&
  canDo({
    action: 'organization/create_scenario',
    permissionContext,
    resourceId: permissionContext.user.organization._id,
  });

const canAdminScenario = (
  permissionContext: PermissionContext,
  scenarioId: ArdoqId,
  activeScenarioState: ScenarioModeState | null
): boolean => {
  // If the scenario is in diff mode, we should not allow administration
  if (
    activeScenarioState &&
    activeScenarioOperations.scenarioIsInDiffMode(
      activeScenarioState,
      scenarioId
    )
  ) {
    return false;
  }
  return (
    hasFeature(Features.SCENARIOS_BETA) &&
    canDo({
      action: 'scenario/admin',
      permissionContext,
      resourceId: scenarioId,
    })
  );
};

const canEditScenario = (
  permissionContext: PermissionContext,
  scenarioId: ArdoqId,
  activeScenarioState: ScenarioModeState | null
): boolean => {
  // If the scenario is in diff mode, we should not allow editing
  if (
    activeScenarioState &&
    activeScenarioOperations.scenarioIsInDiffMode(
      activeScenarioState,
      scenarioId
    )
  ) {
    return false;
  }

  return (
    hasFeature(Features.SCENARIOS_BETA) &&
    canDo({
      action: 'scenario/edit',
      permissionContext,
      resourceId: scenarioId,
    })
  );
};

const canEditActiveScenario = (
  permissionContext: PermissionContext,
  activeScenarioState: ActiveScenarioState
): boolean => {
  const activeScenarioId =
    activeScenarioOperations.getActiveScenarioId(activeScenarioState);

  return canEditScenario(
    permissionContext,
    activeScenarioId,
    activeScenarioState
  );
};

export const scenarioAccessControlInterface = {
  canCreateScenario,
  canAdminScenario,
  canEditScenario,
  canEditActiveScenario,
};
