import { StubTag } from '@ardoq/data-model';

const toTagOption = ({ name }: StubTag) => ({
  label: name,
  value: name,
});

// Should be the only export aside from types
export const tagOptionOperations = {
  toTagOption,
};
