import { StubReferenceWithTypeName } from '@ardoq/data-model';
import { APIReferenceTypeAttributes, LabeledValue } from '@ardoq/api-types';

const toReferenceOptionWithDisplayTextOrTypeAndComponentNames = (
  reference: StubReferenceWithTypeName,
  sourceComponentName: string,
  targetComponentName: string
) => {
  const label = toReferenceLabelWithDisplayTextOrTypeAndComponentNames({
    reference,
    sourceComponentName,
    targetComponentName,
  });

  return {
    label,
    value: reference._id,
  };
};

type ReferenceWithSourceAndTargetComponentNames = {
  reference: StubReferenceWithTypeName;
  sourceComponentName: string;
  targetComponentName: string;
};

const toReferenceLabelWithDisplayTextOrTypeAndComponentNames = ({
  reference,
  sourceComponentName,
  targetComponentName,
}: ReferenceWithSourceAndTargetComponentNames) => {
  return (
    reference.displayText ??
    `Type "${reference.typeName}", from "${sourceComponentName}" to "${targetComponentName}"`
  );
};

const toSelectOptionByName = (
  reference: APIReferenceTypeAttributes
): LabeledValue => {
  return {
    label: reference.name,
    value: reference.name,
  };
};

// Should be the only export aside from types
export const referenceOptionOperations = {
  toReferenceOptionWithDisplayTextOrTypeAndComponentNames,
  toReferenceLabelWithDisplayTextOrTypeAndComponentNames,
  toSelectOptionByName,
};
