import { FormattingRule } from '@ardoq/perspectives';
import {
  createFormattingRule,
  deleteFormattingRule,
  reorderFormattingRules,
  selectComponentLabelFormattingValue,
  selectReferenceLabelFormattingValue,
  setComponentLabelFormattingTimeIncluded,
  setReferenceLabelFormattingTimeIncluded,
  updateFormattingRule,
  createLabelFormatting,
  reorderLabelFormatting,
  deleteLabelFormatting,
  updateLabelFormattingAt,
  showReferenceTypeToggled,
} from './perspectivesEditor$/actions';
import { dispatchAction } from '@ardoq/rxbeach';
import { LabelFormattingInfo } from '@ardoq/data-model';

const onComponentLabelFormattingValueSelected = (labelFormatting: string) => {
  dispatchAction(selectComponentLabelFormattingValue(labelFormatting));
};
const onReferenceLabelFormattingValueSelected = (labelFormatting: string) => {
  dispatchAction(selectReferenceLabelFormattingValue(labelFormatting));
};
const onFormattingRuleDeleted = (deletedRuleId: string) =>
  dispatchAction(deleteFormattingRule(deletedRuleId));
const onFormattingRuleReordered = (reorderedGroupingRules: string[]) =>
  dispatchAction(reorderFormattingRules(reorderedGroupingRules));
const onFormattingRuleUpdated = (
  updatedFormattingRuleId: string,
  updatedFormattingRule: Partial<FormattingRule>
) =>
  dispatchAction(
    updateFormattingRule({ updatedFormattingRuleId, updatedFormattingRule })
  );
const onFormattingRuleAdded = (newFormattingRule: FormattingRule) =>
  dispatchAction(createFormattingRule(newFormattingRule));
const setComponentLabelTimeIncluded = (isChecked: boolean) =>
  dispatchAction(setComponentLabelFormattingTimeIncluded(isChecked));
const setReferenceLabelTimeIncluded = (isChecked: boolean) =>
  dispatchAction(setReferenceLabelFormattingTimeIncluded(isChecked));
const onLabelFormattingAdded = (labelFormatting: LabelFormattingInfo) =>
  dispatchAction(createLabelFormatting(labelFormatting));
const onLabelFormattingReordered = (
  reorderedLabelFormatting: LabelFormattingInfo[]
) => dispatchAction(reorderLabelFormatting(reorderedLabelFormatting));
const onLabelFormattingDeleted = (deletedLabelFormattingIndex: number) =>
  dispatchAction(deleteLabelFormatting(deletedLabelFormattingIndex));
const onUpdateLabelFormattingAt = (
  index: number,
  value: Partial<LabelFormattingInfo>
) => dispatchAction(updateLabelFormattingAt({ index, ...value }));
const onShowReferenceTypeToggled = (showReferenceType: boolean) =>
  dispatchAction(showReferenceTypeToggled(showReferenceType));

export const formattingCommands = {
  onComponentLabelFormattingValueSelected,
  onReferenceLabelFormattingValueSelected,
  onFormattingRuleDeleted,
  onFormattingRuleReordered,
  onFormattingRuleUpdated,
  onFormattingRuleAdded,
  setComponentLabelTimeIncluded,
  setReferenceLabelTimeIncluded,
  onLabelFormattingAdded,
  onLabelFormattingReordered,
  onLabelFormattingDeleted,
  onUpdateLabelFormattingAt,
  onShowReferenceTypeToggled,
};
