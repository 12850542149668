import type { PerspectivesFormattingOptions } from '@ardoq/perspectives';
import type { FormattingTabState } from '../types';

export const getAppliedFormattingState = (
  formattingOptions: PerspectivesFormattingOptions
): Omit<
  FormattingTabState,
  | 'formattingOptions'
  | 'isAppliedFormattingIncluded'
  | 'perspectivesRelatedData'
> => ({
  conditionalFormattingRules:
    formattingOptions.appliedConditionalFormattingRules,
  componentLabelFormattingValue:
    formattingOptions.appliedComponentFormattingLabel,
  referenceLabelFormattingValue:
    formattingOptions.appliedReferenceFormattingLabel,
  isComponentLabelTimeIncluded:
    formattingOptions.isAppliedComponentLabelTimeIncluded,
  isReferenceLabelTimeIncluded:
    formattingOptions.isAppliedReferenceLabelTimeIncluded,
});
